.lang-switcher {
    z-index: 2;
    width: 150px;
    position: fixed;
    top: 30px;
    right: 30px;
    padding: 7px 10px;
    background-color: #000;
    color: #fff;
    border: none;
    text-align: left;
    &:focus {
        border-color: #a81618;
        box-shadow: 0 0 5px transparentize(#a81618, 0.1);
    }
}
::selection {
    background: #a81618;
}

/** hide sidebar for now **/
#global-sidebar {
    display: none;
}
